const en = {
    en: {
        translations: {
            error: {
                camera: "Un problème est survenu lors de la récupération des caméras",
                algo: "Un problème est survenu lors de la récupération des algorithmes",
            },
            settings: {
                title: "Configuration globale",
                notFound: `Configuration non trouvée`,
            },
            selection: {
                dateStart: `Date de début`,
                dateStop: `Date de fin`,
                camera: `Caméra`,
            },
            results: {
                algoDetail: `Détails de la prise de vue pour chacun des algorithmes`,
                visualization: `Visualisation des résultats`,
                selectResults: `Selectionner des résultats`,
                selectPicture: `Selectionner une prise de vue`,
                noResults: `Aucun résultats`,
                allCameras: `Toutes`
            },
            algo: {
                testForCamera: `Tester les algos pour la caméra `,
                settings: `Configuration des algorithmes de la camera {{ camera }}`,
                noAlgo: `Auncun algorithme`,
                notFound: `Algorithme non trouvé`,
                jsonSchemaNotFound: `JSON format non trouvé`,
            },
            configuration: `Configuration`,
            camera: {
                live: `Live {{ camera }}`,
                generalInfo: `Informations de la caméra`,
                noCamera: `Aucune caméra`,
                notFound: `Caméra non trouvée`,
                color: `Retour vidéo`,
                depth: `Retour distance`,
            },
            actions: {
                test: `Tester`,
                save: `Enregistrer`,
                search: `Rechercher`,
                downloadCsv: `Télécharger (CSV)`
            },
            drawer: {
                algos: `Algorithmes`,
                config: `Configuration`,
                settings: `Réglages système`,
                settingsTooltip: `Réglages système`,
                results: `Résultats`,
                resultsTooltip: `Visualisation des résultats`,
                cameras: `Caméras`,
                camerasTooltip: `Configuration des caméras`,
                cameraTooltip: `Configurer la caméra {{ camera_id }}`,
                algosTooltip: `Configuration des algorithmes`,
                algoTooltip: `Configurer les algorithmes de la caméra {{ camera_id }}`,
            },
        },
    },
};

export default en;
