import { ActionTypes } from "./actionTypes";
import * as settingsService from "services/api/settings.service";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type Dispatch = (action: Action) => void;

export type Action =
    | {
          type: ActionTypes.GET_SETTINGS_START;
      }
    | {
          type: ActionTypes.GET_SETTINGS_FINISH;
          payload: { data: any[] };
      }
    | {
          type: ActionTypes.GET_SETTINGS_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.POST_SETTINGS_START;
      }
    | {
          type: ActionTypes.POST_SETTINGS_FINISH;
      }
    | {
          type: ActionTypes.POST_SETTINGS_FAIL;
          payload: { error: string };
      };

// ---------------------------------------------------------------------
//                             ACTIONS
//----------------------------------------------------------------------

export enum Status {
    INIT = "Init",
    STARTING = "Starting",
    FINISH = "Finish",
    ERROR = "Error",
}

export async function getSettings(dispatch: Dispatch) {
    try {
        dispatch({ type: ActionTypes.GET_SETTINGS_START });

        const response = await settingsService.getSettings();

        if (response.status === 200) {
            const { data } = response;

            dispatch({
                type: ActionTypes.GET_SETTINGS_FINISH,
                payload: { data: data },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_SETTINGS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_SETTINGS_FAIL,
            payload: { error: err.message },
        });
    }
}

export interface IPostSettingsPayload {
    settings: any;
}

export async function postSettings(
    dispatch: Dispatch,
    payload: IPostSettingsPayload
) {
    try {
        dispatch({ type: ActionTypes.POST_SETTINGS_START });

        const apiPayload: settingsService.IApiPostSettingsPayload = {
            settings: payload.settings,
        };

        const response = await settingsService.postSettings(apiPayload);

        if (response.status === 200) {
            dispatch({
                type: ActionTypes.POST_SETTINGS_FINISH,
            });
            getSettings(dispatch);
        } else {
            dispatch({
                type: ActionTypes.POST_SETTINGS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.POST_SETTINGS_FAIL,
            payload: { error: err.message },
        });
    }
}
