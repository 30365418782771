import axios from "axios";
import injectToken from "services/api/interceptor/injectToken";

const API_URL = process.env.REACT_APP_API_SERVER;

export function getInstance() {
    return axios.create({
        baseURL: API_URL,
    });
}

export function getAuthenticatedInstance() {
    const instance = getInstance();

    instance.interceptors.request.use(injectToken);

    return instance;
}

export function getResourceInstance() {
    return axios.create();
}

export function getAuthenticatedResourceInstance() {
    const instance = getResourceInstance();

    instance.interceptors.request.use(injectToken);

    return instance;
}
