import * as cameraActions from "features/camera/context/camera.action";
import cameraConfig from "features/camera/camera.config";
import waitFor from "helpers/waitFor.helpers";

export default class AcquisitionService {
    private cameraId: string | null;
    private dispatch: cameraActions.Dispatch | null;
    private cameraActions: any;

    constructor() {
        this.cameraId = null;
        this.dispatch = null;
        this.cameraActions = cameraActions;
    }

    stopAcquisition() {
        this.cameraId = null;
        this.dispatch = null;
    }

    // Take a picture after receiving the result of the previous picture
    async startAcquisition(
        cameraId: string | null,
        dispatch: cameraActions.Dispatch
    ) {
        this.cameraId = cameraId;
        this.dispatch = dispatch;

        if (!this.cameraId || !this.dispatch) return;

        const payloadImgColor: cameraActions.IAcquireCameraPayload = {
            cameraId: this.cameraId,
            mode: cameraConfig.cameraMode.COLOR,
        };

        await this.cameraActions.acquireCamera(this.dispatch, payloadImgColor);

        const payloadImgDepth: cameraActions.IAcquireCameraPayload = {
            cameraId: this.cameraId,
            mode: cameraConfig.cameraMode.DEPTH,
        };

        await this.cameraActions.acquireCamera(this.dispatch, payloadImgDepth);

        await waitFor(cameraConfig.cameraRefreshTime);

        this.startAcquisition(this.cameraId, this.dispatch);
    }
}
