import { getAuthenticatedInstance } from "services/api/helpers/axios.helpers";
const authenticatedInstance = getAuthenticatedInstance();

export interface IApiGetResultsPayload {
  camera_id: string | null;
  date_start: string;
  date_stop: string;
}

export async function getResults(payload: IApiGetResultsPayload): Promise<any> {
  return authenticatedInstance.post("/results", payload);
}

export async function getCsvResults(payload: IApiGetResultsPayload): Promise<any> {
    return authenticatedInstance.post("/csv_results", payload);
  }
