import * as React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useBinary from "hooks/useBinaryImage";

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        marginTop: theme.spacing(1),
        border: `1px solid #ddd`,
        borderRadius: 4,
        padding: theme.spacing(2),
    },
}));

interface IProps {
    imgColor: Uint8Array;
    imgDepth: Uint8Array;
    classe?: string;
}

export default function Live({ imgColor, imgDepth, classe }: IProps) {
    const imgColorUrl = useBinary(imgColor);
    const imgDepthUrl = useBinary(imgDepth);
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            style={{ width: "none" }}
            container
            direction="row"
            spacing={2}
            justifyContent="center"
            className={classe ? classe : undefined}
        >
            {imgColorUrl && (
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    xs={12}
                    md={6}
                >
                    <Grid item>
                        <img
                            src={imgColorUrl}
                            alt="acquire"
                            height="250"
                            className={classes.image}
                        />
                    </Grid>
                    <Grid item>
                        <Typography color="textSecondary">
                            {t("camera.color")}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {imgDepthUrl && (
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    xs={12}
                    md={6}
                >
                    <Grid item>
                        <img
                            src={imgDepthUrl}
                            alt="acquire"
                            height="250"
                            className={classes.image}
                        />
                    </Grid>
                    <Grid item>
                        <Typography color="textSecondary">
                            {t("camera.depth")}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
