export interface Languages {
  [index: string]: string;
}

const languages: Languages = {
  "fr-FR": "fr",
  "en-EN": "en",
};

const lang = navigator.language;

const config = {
  fallbackLng: `${languages[lang]}`,
  debug: true,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
};

export default config;
