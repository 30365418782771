import { ActionTypes } from "./actionTypes";
import * as algoService from "services/api/algo.service";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type Dispatch = (action: Action) => void;

export type Action =
    | {
          type: ActionTypes.GET_ALGOS_START;
      }
    | {
          type: ActionTypes.GET_ALGOS_FINISH;
          payload: { data: any[] };
      }
    | {
          type: ActionTypes.GET_ALGOS_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.GET_ALGOS_JSON_SCHEMA_START;
      }
    | {
          type: ActionTypes.GET_ALGOS_JSON_SCHEMA_FINISH;
          payload: { data: any };
      }
    | {
          type: ActionTypes.GET_ALGOS_JSON_SCHEMA_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.POST_ALGO_START;
      }
    | {
          type: ActionTypes.POST_ALGO_FINISH;
      }
    | {
          type: ActionTypes.POST_ALGO_FAIL;
          payload: { error: string };
      };

// ---------------------------------------------------------------------
//                             ACTIONS
//----------------------------------------------------------------------

export enum Status {
    INIT = "Init",
    STARTING = "Starting",
    FINISH = "Finish",
    ERROR = "Error",
}

export async function getAlgos(dispatch: Dispatch) {
    try {
        dispatch({ type: ActionTypes.GET_ALGOS_START });

        const response = await algoService.getAlgos();

        if (response.status === 200) {
            const { data } = response;

            dispatch({
                type: ActionTypes.GET_ALGOS_FINISH,
                payload: { data: data },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_ALGOS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_ALGOS_FAIL,
            payload: { error: err.message },
        });
    }
}

export async function getAlgosJSONSchema(dispatch: Dispatch) {
    try {
        dispatch({ type: ActionTypes.GET_ALGOS_JSON_SCHEMA_START });

        const response = await algoService.getAlgosJSONSchema();

        if (response.status === 200) {
            const { data } = response;

            dispatch({
                type: ActionTypes.GET_ALGOS_JSON_SCHEMA_FINISH,
                payload: { data: data },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_ALGOS_JSON_SCHEMA_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_ALGOS_JSON_SCHEMA_FAIL,
            payload: { error: err.message },
        });
    }
}

export interface IPostAlgoPayload {
    settings: any;
}

export async function postAlgo(dispatch: Dispatch, payload: IPostAlgoPayload) {
    try {
        dispatch({ type: ActionTypes.POST_ALGO_START });

        const apiPayload: algoService.IApiPostAlgoPayload = {
            settings: [payload.settings],
        };

        const response = await algoService.postAlgo(apiPayload);

        if (response.status === 200) {
            dispatch({
                type: ActionTypes.POST_ALGO_FINISH,
            });
            getAlgos(dispatch);
        } else {
            dispatch({
                type: ActionTypes.POST_ALGO_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.POST_ALGO_FAIL,
            payload: { error: err.message },
        });
    }
}
