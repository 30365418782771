import { ActionTypes } from "./actionTypes";
import { Action, Status } from "./resource.action";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type State = {
    status: Status;
    img: null | Uint8Array;
    error: string | null;
};

// ---------------------------------------------------------------------
//                             REDUCER
//----------------------------------------------------------------------

export const initialState: State = {
    status: Status.INIT,
    img: null,
    error: null,
};

export function reducer(state: State, action: Action) {
    switch (action.type) {
        case ActionTypes.GET_COLOR_IMAGE_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.GET_COLOR_IMAGE_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
                img: action.payload.data,
            };
        }
        case ActionTypes.GET_COLOR_IMAGE_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }

        default: {
            throw new Error(`Unhandled action type `);
        }
    }
}
