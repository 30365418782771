import { getAuthenticatedInstance } from "services/api/helpers/axios.helpers";
const authenticatedInstance = getAuthenticatedInstance();

export async function getSettings(): Promise<any> {
    return authenticatedInstance.get("/settings");
}

export interface IApiPostSettingsPayload {
    settings: any[];
}

export async function postSettings(
    payload: IApiPostSettingsPayload
): Promise<any> {
    return authenticatedInstance.post("/settings", payload.settings);
}
