import * as React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import {
    useResults,
    resultsActions,
} from "features/results/context/results.context";

import { useCamera } from "features/camera/context/camera.context";
import { IApiGetResultsPayload, getCsvResults } from "services/api/results.service";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2,0),
    },
    formControl: {
        minWidth: 250,
    },
}));

export default function ResultsSelection() {
    const { t } = useTranslation();
    const [, dispatch] = useResults();
    const [{ data: cameras }] = useCamera();
    const [selectedCameraId, setSelectedCameraId] = React.useState<null | string>(null);
    const [dateStart, setDateStart] = React.useState(new Date());
    const [dateStop, setDateStop] = React.useState(new Date());

    const classes = useStyles();

    React.useEffect(() => {
        if (cameras && cameras.length > 0) {
            setSelectedCameraId(cameras[0].id);
        }
    }, [cameras, setSelectedCameraId]);

    function handleSearch() {
        if (dateStart && dateStop) {
            const payload: resultsActions.IGetResultsPayload = {
                cameraId: selectedCameraId,
                dateStart: dateStart.toISOString(),
                dateStop: dateStop.toISOString(),
            };
            resultsActions.getResults(dispatch, payload);
        }
    }

    async function handleDownload() {
        if (dateStart && dateStop) {
            const payload: IApiGetResultsPayload = {
                camera_id: selectedCameraId,
                date_start: dateStart.toISOString(),
                date_stop: dateStop.toISOString(),
            };
            const data = await getCsvResults(payload);
            const link = document.createElement('a');
            const blob = new Blob([data.data], {
            type: 'application/csv',
            });
            link.href = URL.createObjectURL(blob);
            link.download = `results.csv`;
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }

    function handleChangeDateStart(date: MaterialUiPickersDate) {
        setDateStart(date as Date);
    }

    function handleChangeDateStop(date: MaterialUiPickersDate) {
        setDateStop(date as Date);
    }

    function handleCameraSelect(event: React.ChangeEvent<{ value: any }>) {
        const value = event.target.value === "all"  ? null : event.target.value;
        setSelectedCameraId(value);
    }

    return (
        <Grid container direction={"column"} alignItems={"flex-start"}>
            <Grid container direction={"row"} spacing={2} className={classes.root}>
                <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="camera-select-label">
                            {t("selection.camera")}
                        </InputLabel>
                        <Select
                            labelId="camera-select-label"
                            id="camera-select-label"
                            value={selectedCameraId === null ? "all" : selectedCameraId}
                            onChange={handleCameraSelect}
                            label={t("selection.camera")}
                        >
                            <MenuItem value={"all"}>
                                <em>{t('results.allCameras')}</em>
                            </MenuItem>
                            {cameras.map((camera: any) => {
                                return (
                                    <MenuItem key={camera.id} value={camera.id}>
                                        {camera.id}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={t("selection.dateStart")}
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        onChange={handleChangeDateStart}
                        value={dateStart}
                    />
                </Grid>
                <Grid item>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={t("selection.dateStop")}
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        onChange={handleChangeDateStop}
                        value={dateStop}
                    />
                </Grid>
            </Grid>
            <Box style={{display:"flex", gap:10}}>
                <Button type="submit" variant="contained" color="primary" onClick={handleSearch}>{t("actions.search")}</Button>
                <Button type="submit" variant="contained" color="primary" onClick={handleDownload}>{t("actions.save")}</Button>
            </Box>
        </Grid>
    );
}
