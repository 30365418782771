import apiConfig from "services/api/api.config";

export default function injectToken(config: any) {
  const updatedConfig = config;

  if (config.authentificationType === "bearer") {
    updatedConfig.headers.Authorization = `Bearer ${apiConfig.token}`;
  } else if (config.authentificationType === "basic" )  {
    updatedConfig.headers.Authorization = "Basic " + btoa(config.username + ":" + config.password);
  }
  return updatedConfig;
}
