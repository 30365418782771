import { makeStyles, Theme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function Loader() {
    const classes = useStyles();

    return (
        <Backdrop open className={classes.backdrop}>
            <CircularProgress />
        </Backdrop>
    );
}
