import * as React from "react";
import clsx from "clsx";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { DataGrid, GridCellParams, MuiEvent } from "@mui/x-data-grid";

import {
    useResults,
    resultsSelector,
} from "features/results/context/results.context";

import { formatDataForDataGrid } from "features/results/results.helpers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
    },
}));

interface IProps {
    setActivePath: React.Dispatch<React.SetStateAction<string | null>>;
    classe?: string;
}

export default function ResultsDataGrid({ setActivePath, classe }: IProps) {
    const [state] = useResults();
    const results = resultsSelector.selectResults(state);
    const classes = useStyles();

    const data = formatDataForDataGrid(results);

    function handleCellClick(
        params: GridCellParams,
        event: MuiEvent<React.MouseEvent>
    ) {
        setActivePath(params.row.acquisition);
    }

    return (
        <>
            {data && (
                <div
                    className={
                        classe ? clsx(classes.root, classe) : classes.root
                    }
                >
                    <DataGrid
                        rows={data.rows}
                        columns={data.columns}
                        pageSize={30}
                        autoHeight
                        columnBuffer={3}
                        disableColumnMenu
                        disableColumnSelector
                        onCellClick={handleCellClick}
                    />
                </div>
            )}
        </>
    );
}
