import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import AppBar from "features/utils/components/appBar.component";
import Drawer from "features/utils/components/drawer/drawer.component";

import { publicRoutes, IRoute } from "router/router.config";

import {
    useCamera,
    cameraActions,
} from "features/camera/context/camera.context";
import { useAlgo, algoActions } from "features/algo/context/algo.context";
import {
    useSettings,
    settingsActions,
} from "features/settings/context/settings.context";

import ScrollToTopOnMount from "features/utils/components/ScrollToTopOnMount.component";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function App() {
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [loadingCameras, setLoadingCameras] = React.useState(false);
    const [loadingAlgos, setLoadingAlgos] = React.useState(false);
    const [{ data: cameras, error: errorCamera }, dispatchCamera] = useCamera();
    const [{ data: algos, error: errorAlgo }, dispatchAlgo] = useAlgo();
    const [, dispatchSettings] = useSettings();
    const { t } = useTranslation();

    // Display snackbar on error
    React.useEffect(() => {
        if (errorCamera) {
            setSnackbarMessage("error.camera");
        }
        if (errorAlgo) {
            setSnackbarMessage("error.algo");
        }
    }, [errorCamera, errorAlgo]);

    // Get initial data for camera settings
    React.useEffect(() => {
        async function getData() {
            setLoadingCameras(true);
            await cameraActions.getCameras(dispatchCamera);
            setLoadingCameras(false);
        }

        getData();
        return () => {
            setLoadingCameras(false);
        };
    }, [dispatchCamera]);

    // Get initial data for algos settings
    React.useEffect(() => {
        async function getData() {
            setLoadingAlgos(true);
            await algoActions.getAlgos(dispatchAlgo);
            await algoActions.getAlgosJSONSchema(dispatchAlgo);
            setLoadingAlgos(false);
        }

        getData();
        return () => {
            setLoadingCameras(false);
        };
    }, [dispatchAlgo]);

    // Get global settings
    React.useEffect(() => {
        settingsActions.getSettings(dispatchSettings);
    }, [dispatchSettings]);

    function handleCloseSnackbar() {
        setSnackbarOpen(false);
        setSnackbarMessage("");
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <ScrollToTopOnMount />
            {snackbarOpen && (
                <Snackbar
                    open={snackbarOpen}
                    // autoHideDuration={10000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert severity="error" onClose={handleCloseSnackbar}>
                        {t(snackbarMessage)}
                    </Alert>
                </Snackbar>
            )}
            <AppBar open={drawerOpen} setOpen={setDrawerOpen} />
            <Drawer
                open={drawerOpen}
                setOpen={setDrawerOpen}
                cameras={cameras}
                loadingCameras={loadingCameras}
                algos={algos}
                loadingAlgos={loadingAlgos}
            />

            <main className={classes.content}>
                <div className={classes.content} />
                <Switch>
                    {publicRoutes.map((publicRoute: IRoute) => (
                        <Route
                            exact
                            key={publicRoute.path}
                            path={publicRoute.path}
                        >
                            {publicRoute.component}
                        </Route>
                    ))}
                </Switch>
            </main>
        </div>
    );
}

export default App;
