import * as React from "react";

import * as algoActions from "./algo.action";
import * as algoSelector from "./algo.selector";

import { State, reducer, initialState } from "./algo.reducer";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

type AlgoProviderProps = { children: React.ReactNode };

// ---------------------------------------------------------------------
//                           CONTEXT && PROVIDER
//----------------------------------------------------------------------

const StateContext = React.createContext<
    { state: State; dispatch: algoActions.Dispatch } | undefined
>(undefined);

function AlgoProvider({ children }: AlgoProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const value = { state, dispatch };

    return (
        <StateContext.Provider value={value}>{children}</StateContext.Provider>
    );
}

// ---------------------------------------------------------------------
//                            CUSTOM HOOKS
//----------------------------------------------------------------------

function useAlgo() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error(`useAlgo must be used within a AlgoProvider`);
    }
    return [context.state, context.dispatch] as [State, algoActions.Dispatch];
}

export { AlgoProvider, useAlgo, algoActions, algoSelector };
