import * as React from "react";

import Canvas from "features/utils/components/canvas.component";

import appConfig from "config/app.config";

function findWidthForHd(height: number) {
    const resolution = {
        w: 1920,
        h: 1080,
    };

    return (height * resolution.w) / resolution.h;
}

export interface Boxe {
    x: number;
    y: number;
    w: number;
    h: number;
}
interface IProps {
    path : string|null;
    algo: any;
    classe?: string;
}

export default function AlgoResultViewer({ path  , algo, classe }: IProps) {
    const canvasHeight = appConfig.canvas.landscape.heigth;
    const canvasWidth = findWidthForHd(appConfig.canvas.landscape.heigth);
    const imgUrl = algo.name === "lai" ? path + "/lai_mask.png" : path +"/color.png" ;

    const drawAlgoResults = React.useCallback(
        (ctx: CanvasRenderingContext2D, boxes: Boxe[]) => {
            if (boxes && boxes.length > 0) {
                boxes.forEach((boxe: Boxe) => {
                    // Draw boxe on the canvas
                    // x, y, width and height are relative to the canvas size
                    const width = boxe.w * ctx.canvas.width;
                    const height = boxe.h * ctx.canvas.height;
                    const x = boxe.x * ctx.canvas.width - width / 2;
                    const y = boxe.y * ctx.canvas.height - height / 2;
                    ctx.strokeStyle = "#00FF00 ";
                    ctx.beginPath();
                    ctx.lineWidth = 2;
                    ctx.strokeRect(x, y, width, height);
                });
                ctx.fill();
            }
        },
        []
    );

    const draw = React.useCallback(
        (ctx: RenderingContext) => {
            if (ctx instanceof CanvasRenderingContext2D) {
                // Draw image
                if (imgUrl) {
                    // Load image
                    const img = new Image();
                    img.onload = function () {
                        ctx.drawImage(
                            img,
                            0,
                            0,
                            ctx.canvas.width,
                            ctx.canvas.height
                        );
                        // Draw algo results
                        if (algo.boxes) {
                            drawAlgoResults(ctx, algo.boxes);
                        }
                    };
                    img.onerror = function (event) {
                        console.log("Error during loading : ", event);
                        ctx.fillStyle = "white";
                        ctx.fillRect(0,
                            0,
                            ctx.canvas.width,
                            ctx.canvas.height);
                        ctx.fillStyle = 'black';
                        ctx.fillText("Cannot load image", 10, 50);
                    };
                    img.src = imgUrl;
                }
            }
        },
        [imgUrl, drawAlgoResults, algo]
    );

    return (
        <>
            {imgUrl && (
                <div className={classe ? classe : undefined}>
                    <Canvas
                        options={{
                            context: "2d",
                            width: canvasWidth,
                            height: canvasHeight,
                        }}
                        draw={draw}
                    />
                </div>
            )}
        </>
    );

}
