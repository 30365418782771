import { State } from "./camera.reducer";

export function selectCameras(state: State) {
    return state.data;
}

export function selectCameraByCameraId(state: State) {
    return function (cameraId: string) {
        const cameras = selectCameras(state);

        return cameras.find((camera: any) => camera.id === cameraId);
    };
}

export function selectColorImg(state: State) {
    return state.imgs.color;
}

export function selectDepthImg(state: State) {
    return state.imgs.depth;
}
