import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";

import theme from "theme/theme.config";

import { i18nInit } from "lang/i18n";
import i18nConfig from "lang/i18n.config";
import en from "lang/en";
import fr from "lang/fr";

import App from "./App";
import { CameraProvider } from "features/camera/context/camera.context";
import { AlgoProvider } from "features/algo/context/algo.context";
import { SettingsProvider } from "features/settings/context/settings.context";
import { ResultsProvider } from "features/results/context/results.context";
import { ResourceProvider } from "features/resource/context/resource.context";

const ressources = {
    ...en,
    ...fr,
};

i18nInit(i18nConfig, ressources);

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <Router>
                    <CameraProvider>
                        <AlgoProvider>
                            <SettingsProvider>
                                <ResourceProvider>
                                    <ResultsProvider>
                                        <App />
                                    </ResultsProvider>
                                </ResourceProvider>
                            </SettingsProvider>
                        </AlgoProvider>
                    </CameraProvider>
                </Router>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
