import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export function i18nInit(config: any, resources: any) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ...config,
      resources: {
        ...resources,
      },
    });
}
