import * as React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import {
    useResults,
    resultsSelector,
    resultsActions,
} from "features/results/context/results.context";

import ResultsSelection from "features/results/components/resultsSelection.component";
import ResultsDataGrid from "features/results/components/resultsDataGrid.component";
import AlgoResultViewer from "features/results/components/algoResultViewer.component";
import Loader from "features/utils/loader";

const useStyles = makeStyles((theme: Theme) => ({
    resultViewer: {
        margin: theme.spacing(4),
    },
    dataGrid: {
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));

export default function ResultsView() {
    const [stateResults] = useResults();
    const results = resultsSelector.selectResults(stateResults);
    const [activePath, setActivePath] = React.useState<string | null>(null);
    const [activeAlgos, setActiveAlgos] = React.useState<any[]>([]);
    const loading = stateResults.status === resultsActions.Status.STARTING;

    const { t } = useTranslation();
    const classes = useStyles();

    React.useEffect(() => {
        if (activePath && results) {
            const algosObject = results[activePath];
            if (algosObject) {
                setActiveAlgos(algosObject.algos);
            }
        }
    }, [activePath, results]);

    return (
        <Container>
            <Typography variant="h5" paragraph>
                {t("results.visualization")}
            </Typography>
            <Typography variant="subtitle2" paragraph>
                {t("results.selectResults")}
            </Typography>

            <ResultsSelection />

            <Divider className={classes.divider} />

            <Typography variant="subtitle2" paragraph>
                {t("results.selectPicture")}
            </Typography>

            {loading && <Loader />}

            {!loading && (
                <>
                    <ResultsDataGrid
                        setActivePath={setActivePath}
                        classe={classes.dataGrid}
                    />

                    {activeAlgos && (
                        <>
                            <Typography variant="h6" paragraph>
                                {t("results.algoDetail")}
                            </Typography>
                            {activeAlgos.map((algo: any) => {
                                return (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            paragraph
                                        >
                                            {algo.name}
                                        </Typography>
                                        <AlgoResultViewer
                                            path={activePath}
                                            algo={algo}
                                            classe={classes.resultViewer}
                                        />
                                    </>
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </Container>
    );
}
