import { ActionTypes } from "./actionTypes";
import { Action, Status } from "./settings.action";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type State = {
    status: Status;
    data: any | null;
    error: string | null;
};

// ---------------------------------------------------------------------
//                             REDUCER
//----------------------------------------------------------------------

export const initialState: State = {
    status: Status.INIT,
    data: null,
    error: null,
};

export function reducer(state: State, action: Action) {
    switch (action.type) {
        case ActionTypes.GET_SETTINGS_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.GET_SETTINGS_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
                data: action.payload.data,
            };
        }
        case ActionTypes.GET_SETTINGS_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }
        case ActionTypes.POST_SETTINGS_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.POST_SETTINGS_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
            };
        }
        case ActionTypes.POST_SETTINGS_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }

        default: {
            throw new Error(`Unhandled action type `);
        }
    }
}
