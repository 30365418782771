import * as React from "react";

function resizeCanvasToDisplaySize(canvasContext: any) {
    const { canvas } = canvasContext;
    const { width, height } = canvas.getBoundingClientRect();

    if (canvas.width !== width || canvas.height !== height) {
        const { devicePixelRatio: ratio = 1 } = window;
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        canvasContext.scale(ratio, ratio);
        return true;
    }
    return false;
}

export interface IOptions {
    context: string;
    height?: number;
    width?: number;
}
interface IProps {
    draw: (ctx: RenderingContext) => void;
    options: IOptions;
}

export default function Canvas({
    options = { context: "2d" },
    draw,
    ...rest
}: IProps) {
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const canvasContext = canvas.getContext(options.context);
            if (canvasContext) {
                resizeCanvasToDisplaySize(canvasContext);
                draw(canvasContext);
            }
        }
    }, [options, draw]);

    return (
        <canvas
            ref={canvasRef}
            {...rest}
            width={options.width || 300}
            height={options.height || 150}
        />
    );
}
