import * as React from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, useTheme, alpha } from "@material-ui/core/styles";
import MuiDrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import FunctionsRoundedIcon from "@material-ui/icons/FunctionsRounded";
import PersonalVideoRoundedIcon from "@material-ui/icons/PersonalVideoRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Tootip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";

import appConfig from "config/app.config";
import { Endpoints } from "router/router.config";
import { replaceParams } from "router/router.helpers";
import logo from "ressources/logo.png";

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        width: appConfig.drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: appConfig.drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logo: {
        padding: theme.spacing(0, 1),
        margin: "auto",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    active: {
        background: alpha(theme.palette.action.active, 0.6),
        color: theme.palette.common.white,
        "& svg": {
            color: theme.palette.common.white,
        },
        "&:hover": {
            backgroundColor: alpha(theme.palette.action.active, 0.4),
        },
    },
    scrollArea: {
        overflow: "auto",
        maxHeight: `calc(100vh - ${100}px)`,
        backgroundColor: "inherit",
        paddingTop: 0,
    },
}));

interface IProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    cameras: any[];
    loadingCameras: boolean;
    algos: any[];
    loadingAlgos: boolean;
}

export default function Drawer({
    open,
    setOpen,
    cameras,
    loadingCameras,
    algos,
    loadingAlgos,
}: IProps) {
    const [cameraListOpen, setCameraListOpen] = React.useState(false);
    const [algoListOpen, setAlgoListOpen] = React.useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    function handleClose() {
        setOpen(false);
    }

    function handlePathClick(path: string) {
        history.push(path);
    }

    function handleCameraAlgoClick(cameraId: string) {
        const path = replaceParams(Endpoints.ALGOS, [/:cameraId/], [cameraId]);
        history.push(path);
    }

    function handleCameraClick(cameraId: string) {
        const path = replaceParams(Endpoints.CAMERA, [/:cameraId/], [cameraId]);
        history.push(path);
    }

    function toggleCameraList() {
        setCameraListOpen(!cameraListOpen);
    }

    function toggleAlgoList() {
        setAlgoListOpen(!algoListOpen);
    }

    return (
        <MuiDrawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <img
                    src={logo}
                    alt="logo"
                    height="42"
                    className={classes.logo}
                />
                <IconButton onClick={handleClose}>
                    {theme.direction === "rtl" ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </div>

            <Divider />

            <List>
                <Tootip
                    title={`${t("drawer.resultsTooltip")}`}
                    placement="right"
                >
                    <ListItem
                        button
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handlePathClick(Endpoints.RESULTS)
                        }
                        className={
                            location.pathname === Endpoints.RESULTS
                                ? classes.active
                                : undefined
                        }
                    >
                        <ListItemIcon>
                            <EqualizerRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("drawer.results")} />
                    </ListItem>
                </Tootip>
            </List>

            <Divider />

            {/* CAMERAS */}
            <List
                className={classes.scrollArea}
                {...(open && {
                    subheader: (
                        <ListSubheader
                            component="li"
                            id="nested-list-subheader"
                        >
                            {t("configuration")}
                        </ListSubheader>
                    ),
                })}
            >
                <Tootip
                    title={`${t("drawer.settingsTooltip")}`}
                    placement="right"
                >
                    <ListItem
                        button
                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handlePathClick(Endpoints.GENERAL)
                        }
                        className={
                            location.pathname === Endpoints.GENERAL
                                ? classes.active
                                : undefined
                        }
                    >
                        <ListItemIcon>
                            <SettingsRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("drawer.settings")} />
                    </ListItem>
                </Tootip>
                <Tootip
                    title={`${t("drawer.camerasTooltip")}`}
                    placement="right"
                >
                    <ListItem button onClick={toggleCameraList}>
                        <ListItemIcon>
                            <VideocamRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("drawer.cameras")} />
                        {cameraListOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tootip>
                <Collapse in={cameraListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {loadingCameras && (
                            <>
                                <ListItem>
                                    <Skeleton width={"80%"} />
                                </ListItem>
                                <ListItem>
                                    <Skeleton width={"70%"} />
                                </ListItem>
                                <ListItem>
                                    <Skeleton width={"100%"} />
                                </ListItem>
                            </>
                        )}
                        {!loadingCameras && cameras.length === 0 && (
                            <ListItem>
                                <ListItemText
                                    primaryTypographyProps={{
                                        align: "center",
                                        color: "textSecondary",
                                    }}
                                >
                                    {t("camera.noCamera")}
                                </ListItemText>
                            </ListItem>
                        )}
                        {!loadingCameras &&
                            cameras.length > 0 &&
                            cameras.map((camera: any) => {
                                return (
                                    <Tootip
                                        key={camera.id}
                                        title={`${t("drawer.cameraTooltip", {
                                            camera_id: camera.id,
                                        })}`}
                                        placement="right"
                                    >
                                        <ListItem
                                            button
                                            className={clsx(classes.nested, {
                                                [classes.active]:
                                                    location.pathname ===
                                                    replaceParams(
                                                        Endpoints.CAMERA,
                                                        [/:cameraId/],
                                                        [camera.id]
                                                    ),
                                            })}
                                            onClick={() =>
                                                handleCameraClick(camera.id)
                                            }
                                        >
                                            <ListItemIcon>
                                                <PersonalVideoRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={camera.id}
                                                primaryTypographyProps={{
                                                    noWrap: true,
                                                }}
                                            />
                                        </ListItem>
                                    </Tootip>
                                );
                            })}
                    </List>
                </Collapse>

                {/* ALGOS */}
                <Tootip title={`${t("drawer.algosTooltip")}`} placement="right">
                    <ListItem button onClick={toggleAlgoList}>
                        <ListItemIcon>
                            <FunctionsRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("drawer.algos")} />
                        {algoListOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                </Tootip>
                <Collapse in={algoListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {loadingAlgos && (
                            <>
                                <ListItem>
                                    <Skeleton width={"80%"} />
                                </ListItem>
                                <ListItem>
                                    <Skeleton width={"70%"} />
                                </ListItem>
                                <ListItem>
                                    <Skeleton width={"100%"} />
                                </ListItem>
                            </>
                        )}
                        {!loadingAlgos && algos.length === 0 && (
                            <ListItem>
                                <ListItemText
                                    primaryTypographyProps={{
                                        align: "center",
                                        color: "textSecondary",
                                    }}
                                >
                                    {t("camera.noAlgo")}
                                </ListItemText>
                            </ListItem>
                        )}
                        {!loadingAlgos &&
                            algos.length > 0 &&
                            algos.map((algo: any) => {
                                return (
                                    <Tootip
                                        key={algo.camera_id}
                                        title={`${t("drawer.algoTooltip", {
                                            camera_id: algo.camera_id,
                                        })}`}
                                        placement="right"
                                    >
                                        <ListItem
                                            button
                                            className={clsx(classes.nested, {
                                                [classes.active]:
                                                    location.pathname ===
                                                    replaceParams(
                                                        Endpoints.ALGOS,
                                                        [/:cameraId/],
                                                        [algo.camera_id]
                                                    ),
                                            })}
                                            onClick={() =>
                                                handleCameraAlgoClick(
                                                    algo.camera_id
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                <VideocamRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={algo.camera_id}
                                            />
                                        </ListItem>
                                    </Tootip>
                                );
                            })}
                    </List>
                </Collapse>
            </List>
        </MuiDrawer>
    );
}
