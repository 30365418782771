import { GridColDef } from "@mui/x-data-grid";

function hasColumnDef(columns: GridColDef[], searchedColumn: GridColDef) {
    return !!columns.find(
        (column: GridColDef) => column.field === searchedColumn.field
    );
}

interface IAlgo {
    name: string;
    value: number;
}

interface IAlgoObject {
    algos: IAlgo[];
}

interface IResults {
    [index: string]: IAlgoObject;
}

interface IRow {
    [index: string]: any;
}

export function formatDataForDataGrid(results: IResults) {
    let id = 1;
    let columns: GridColDef[] = [{ field: "id", headerName: "id", width: 80 }];
    const rows: IRow[] = [];

    if (!results) return null;

    for (const [path, algosObject] of Object.entries(results)) {
        const column: GridColDef = {
            field: "acquisition",
            headerName: "acquisition",
            minWidth: 300,
        };
        const row: IRow = {
            id: id,
            acquisition: path,
        };

        if (!hasColumnDef(columns, column)) {
            columns.push(column);
        }

        // Format each algos for data grid Mui
        const { algos } = algosObject;

        for (const algo of algos) {
            const column: GridColDef = {
                field: algo.name,
                headerName: algo.name,
                minWidth: 150,
            };
            if (!hasColumnDef(columns, column)) {
                columns.push(column);
            }
            if (algo.hasOwnProperty("value")) {
                row[algo.name] = algo.value;
            }
        }

        rows.push(row);
        id++;
    }

    return { columns, rows };
}
