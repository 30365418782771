import { getAuthenticatedInstance } from "services/api/helpers/axios.helpers";
const authenticatedInstance = getAuthenticatedInstance();

export async function getAlgos(): Promise<any> {
    return authenticatedInstance.get("/algorithms");
}

export async function getAlgosJSONSchema(): Promise<any> {
    return authenticatedInstance.get("/algorithms_json_schema");
}

export interface IApiPostAlgoPayload {
    settings: any[];
}

export async function postAlgo(payload: IApiPostAlgoPayload): Promise<any> {
    return authenticatedInstance.post("/algorithms", payload.settings);
}
