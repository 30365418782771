import { ActionTypes } from "./actionTypes";
import * as resourceService from "services/api/resource.service";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type Dispatch = (action: Action) => void;

export type Action =
    | {
          type: ActionTypes.GET_COLOR_IMAGE_START;
      }
    | {
          type: ActionTypes.GET_COLOR_IMAGE_FINISH;
          payload: { data: Uint8Array | null };
      }
    | {
          type: ActionTypes.GET_COLOR_IMAGE_FAIL;
          payload: { error: string };
      };

// ---------------------------------------------------------------------
//                             ACTIONS
//----------------------------------------------------------------------

export enum Status {
    INIT = "Init",
    STARTING = "Starting",
    FINISH = "Finish",
    ERROR = "Error",
}

export async function getColorImage(dispatch: Dispatch, endpoint: string) {
    try {
        dispatch({ type: ActionTypes.GET_COLOR_IMAGE_START });

        const response = await resourceService.getBinaryResource(endpoint);

        if (response.status === 200) {
            const { data } = response;

            dispatch({
                type: ActionTypes.GET_COLOR_IMAGE_FINISH,
                payload: { data: data },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_COLOR_IMAGE_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_COLOR_IMAGE_FAIL,
            payload: { error: err.message },
        });
    }
}
