/**
 * Replace route params
 * @param path
 * @param rules
 * @param params
 */
export function replaceParams(path: string, rules: RegExp[], params: string[]) {
  rules.forEach((rule: RegExp, index: number) => {
    const param = params[index];
    path = path.replace(rule, param);
  });

  return path;
}
