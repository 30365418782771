import * as React from "react";

import * as settingsActions from "./settings.action";
import * as settingsSelector from "./settings.selector";

import { State, reducer, initialState } from "./settings.reducer";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

type SettingsProviderProps = { children: React.ReactNode };

// ---------------------------------------------------------------------
//                           CONTEXT && PROVIDER
//----------------------------------------------------------------------

const StateContext = React.createContext<
    { state: State; dispatch: settingsActions.Dispatch } | undefined
>(undefined);

function SettingsProvider({ children }: SettingsProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const value = { state, dispatch };

    return (
        <StateContext.Provider value={value}>{children}</StateContext.Provider>
    );
}

// ---------------------------------------------------------------------
//                            CUSTOM HOOKS
//----------------------------------------------------------------------

function useSettings() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error(`useSettings must be used within a SettingsProvider`);
    }
    return [context.state, context.dispatch] as [
        State,
        settingsActions.Dispatch
    ];
}

export { SettingsProvider, useSettings, settingsActions, settingsSelector };
