import { ActionTypes } from "./actionTypes";
import { Action, Status } from "./camera.action";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type State = {
    status: Status;
    data: any[];
    imgs: {
        color: null | Uint8Array;
        depth: null | Uint8Array;
    };
    error: string | null;
};

// ---------------------------------------------------------------------
//                             REDUCER
//----------------------------------------------------------------------

export const initialState: State = {
    status: Status.INIT,
    data: [],
    imgs: {
        color: null,
        depth: null,
    },
    error: null,
};

export function reducer(state: State, action: Action) {
    switch (action.type) {
        case ActionTypes.GET_CAMERAS_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.GET_CAMERAS_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
                data: action.payload.data,
            };
        }
        case ActionTypes.GET_CAMERAS_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }
        case ActionTypes.POST_CAMERA_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.POST_CAMERA_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
            };
        }
        case ActionTypes.POST_CAMERA_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }
        case ActionTypes.ACQUIRE_CAMERA_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_COLOR: {
            return {
                ...state,
                status: Status.FINISH,
                imgs: {
                    ...state.imgs,
                    color: action.payload.img,
                },
            };
        }
        case ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_DEPTH: {
            return {
                ...state,
                status: Status.FINISH,
                imgs: {
                    ...state.imgs,
                    depth: action.payload.img,
                },
            };
        }
        case ActionTypes.ACQUIRE_CAMERA_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }
        case ActionTypes.PROCESS_CAMERA_START: {
            return {
                ...state,
                error: null,
                status: Status.STARTING,
            };
        }
        case ActionTypes.PROCESS_CAMERA_FINISH: {
            return {
                ...state,
                status: Status.FINISH,
            };
        }
        case ActionTypes.PROCESS_CAMERA_FAIL: {
            return {
                ...state,
                status: Status.ERROR,
                error: action.payload.error,
            };
        }
        default: {
            throw new Error(`Unhandled action type `);
        }
    }
}
