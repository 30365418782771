import * as React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import SettingsForm from "features/camera/components/settingsForm/settingsForm.component";
import {
    useCamera,
    cameraSelector,
} from "features/camera/context/camera.context";

import AcquisitionService from "features/camera/acquisition.service";

import Live from "features/camera/components/live.component";

const acquisitionService = new AcquisitionService();

const useStyles = makeStyles((theme: Theme) => ({
    live: {
        marginBottom: theme.spacing(2),
    },
}));

interface IParams {
    cameraId: string;
}

export default function CameraView() {
    const { cameraId } = useParams<IParams>();
    const [state, dispatch] = useCamera();
    const camera = cameraSelector.selectCameraByCameraId(state)(cameraId);
    const imgColor = cameraSelector.selectColorImg(state);
    const imgDepth = cameraSelector.selectDepthImg(state);
    const { t } = useTranslation();
    const classes = useStyles();

    // Start picture acquirement mechanism
    React.useEffect(() => {
        acquisitionService.startAcquisition(cameraId, dispatch);

        return () => {
            acquisitionService.stopAcquisition();
        };
    }, [dispatch, cameraId]);

    return (
        <Container>
            {camera && (
                <>
                    <Typography variant="h5" paragraph>
                        {t("camera.live", { camera: camera.id })}
                    </Typography>
                    {imgColor && imgDepth && (
                        <Live
                            imgColor={imgColor}
                            imgDepth={imgDepth}
                            classe={classes.live}
                        />
                    )}

                    <Typography variant="h5" paragraph>
                        {t("camera.generalInfo")}
                    </Typography>
                </>
            )}
            {camera && <SettingsForm camera={camera} />}
            {!camera && (
                <Typography variant="h6">{t("camera.notFound")}</Typography>
            )}
        </Container>
    );
}
