import * as React from "react";

export type Type = "image/png" | "image/jpeg";

export interface IOptions {
    type?: Type;
}

/**
 * Convert a binary image into a local url readable by <img /> tag
 *
 * @param binary
 * @param options
 * @returns binaryUrl
 */
export default function useBinaryImage(
    binary: Uint8Array | null,
    options?: IOptions
) {
    const [url, setUrl] = React.useState<string | null>(null);

    React.useEffect(() => {
        try {
            if (!binary) {
                throw new Error("binary not found");
            }
            // Decode Uint8Array image and generate an url
            const blob = new Blob([binary], {
                type: options && options.type ? options.type : "image/png",
            });

            const fileUrl = URL.createObjectURL(blob);

            if (fileUrl) {
                setUrl(fileUrl);
            }
        } catch (error) {
            console.error(error);
        }
    }, [binary, options]);

    return url;
}
