import { useTranslation } from "react-i18next";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import SettingsForm from "features/settings/components/settingsForm/settingsForm.component";
import {
    useSettings,
    settingsSelector,
} from "features/settings/context/settings.context";

export default function CameraView() {
    const { t } = useTranslation();
    const [state] = useSettings();
    const settings = settingsSelector.selectSettings(state);
    return (
        <Container>
            <Typography variant="h5" paragraph>
                {t("settings.title")}
            </Typography>
            {settings && <SettingsForm settings={settings} />}
            {!settings && (
                <Typography variant="h6">{t("settings.notFound")}</Typography>
            )}
        </Container>
    );
}
