import * as React from "react";

import * as cameraActions from "./camera.action";
import * as cameraSelector from "./camera.selector";

import { State, reducer, initialState } from "./camera.reducer";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

type CameraProviderProps = { children: React.ReactNode };

// ---------------------------------------------------------------------
//                           CONTEXT && PROVIDER
//----------------------------------------------------------------------

const StateContext = React.createContext<
    { state: State; dispatch: cameraActions.Dispatch } | undefined
>(undefined);

function CameraProvider({ children }: CameraProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const value = { state, dispatch };

    return (
        <StateContext.Provider value={value}>{children}</StateContext.Provider>
    );
}

// ---------------------------------------------------------------------
//                            CUSTOM HOOKS
//----------------------------------------------------------------------

function useCamera() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error(`useCamera must be used within a CameraProvider`);
    }
    return [context.state, context.dispatch] as [State, cameraActions.Dispatch];
}

export { CameraProvider, useCamera, cameraActions, cameraSelector };
