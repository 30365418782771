import * as React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
    useAlgo,
    algoSelector,
    algoActions,
} from "features/algo/context/algo.context";
import {
    useCamera,
    cameraActions,
} from "features/camera/context/camera.context";

import SettingsForm from "features/algo/components/settingsForm/settingsForm.component";
import Loader from "features/utils/loader";

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        margin: theme.spacing(2, 0),
    },
}));

interface IParams {
    cameraId: string;
}

export default function AlgosView() {
    const { cameraId } = useParams<IParams>();
    const [, dispatchCamera] = useCamera();
    const [state] = useAlgo();
    const [loadingCameraProcessing, setLoadingCameraProcessing] =
        React.useState(false);
    const loadingAlgos = state.status === algoActions.Status.STARTING;
    const jsonSchema = algoSelector.selectAlgosJSONSchema(state);
    const algo = algoSelector.selectAlgoByCameraId(state)(cameraId);
    const { t } = useTranslation();
    const classes = useStyles();

    async function processCamera(event: React.MouseEvent<HTMLButtonElement>) {
        const payload: cameraActions.IProcessCameraPayload = {
            cameraId: cameraId,
        };

        setLoadingCameraProcessing(true);
        cameraActions.processCamera(dispatchCamera, payload);
        setLoadingCameraProcessing(false);
    }

    return (
        <Container>
            {loadingAlgos && <Loader />}
            {!loadingAlgos && algo && jsonSchema && (
                <>
                    <Typography variant="h5" paragraph>
                        {t("algo.settings", { camera: algo.camera_id })}
                    </Typography>
                    <SettingsForm camera={algo} jsonSchema={jsonSchema} />
                </>
            )}
            {!loadingAlgos && algo && jsonSchema && (
                <>
                    <Divider className={classes.divider} />
                    <Typography variant="h5" paragraph>
                        {t("algo.testForCamera")}
                    </Typography>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={processCamera}
                    >
                        {loadingCameraProcessing && (
                            <CircularProgress size={20} color="inherit" />
                        )}
                        {!loadingCameraProcessing && (
                            <Typography>{t("actions.test")}</Typography>
                        )}
                    </Button>
                </>
            )}
            {!loadingAlgos && !algo && (
                <Typography variant="h6">{t("algo.notFound")}</Typography>
            )}
            {!loadingAlgos && !jsonSchema && (
                <Typography variant="h6">
                    {t("algo.jsonSchemaNotFound")}
                </Typography>
            )}
        </Container>
    );
}
