import * as React from "react";
import { useTranslation } from "react-i18next";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Form from "@rjsf/material-ui";
import { ISubmitEvent, UiSchema } from "@rjsf/core";
import type { JSONSchema7 } from "json-schema";

import Button from "@material-ui/core/Button";

import {
    useSettings,
    settingsActions,
} from "features/settings/context/settings.context";

import schema from "./schema.json";
import uiSchema from "./uiSchema.json";

interface IProps {
    settings: any;
}

export default function SettingsForm({ settings }: IProps) {
    const [, dispatch] = useSettings();
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();

    async function handleSubmit(event: ISubmitEvent<unknown>) {
        const { formData } = event;
        const payload: settingsActions.IPostSettingsPayload = {
            settings: formData,
        };
        setLoading(true);
        await settingsActions.postSettings(dispatch, payload);
        setLoading(false);
    }

    return (
        <Form
            schema={schema as JSONSchema7}
            uiSchema={uiSchema as UiSchema}
            onSubmit={handleSubmit}
            formData={settings}
        >
            <Button type="submit" variant="contained" color="primary">
                {loading && <CircularProgress size={20} color="inherit" />}
                {!loading && <Typography>{t("actions.save")}</Typography>}
            </Button>
        </Form>
    );
}
