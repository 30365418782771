import { ActionTypes } from "./actionTypes";
import * as resultsService from "services/api/results.service";
import * as resourceService from "services/api/resource.service";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type Dispatch = (action: Action) => void;

export type Action =
    | {
          type: ActionTypes.GET_RESULTS_START;
      }
    | {
          type: ActionTypes.GET_RESULTS_FINISH;
          payload: { data: any[] };
      }
    | {
          type: ActionTypes.SAVED_RESULTS_PATHS_LIST;
          payload: { pathList: string[] };
      }
    | {
          type: ActionTypes.GET_RESULTS_FAIL;
          payload: { error: string };
      };

// ---------------------------------------------------------------------
//                             ACTIONS
//----------------------------------------------------------------------

async function getAlgosResults(dispatch: Dispatch, pathList: string[]) {
    const resultsData: any = {};

    for (const path in pathList) {
        const endpoint = `${pathList[path]}/results.json`;
        const response = await resourceService.getResource(endpoint);
        if (response.status === 200) {
            resultsData[pathList[path]] = response.data;
        } else {
            dispatch({
                type: ActionTypes.GET_RESULTS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    }
    return resultsData;
}

export enum Status {
    INIT = "Init",
    STARTING = "Starting",
    FINISH = "Finish",
    ERROR = "Error",
}

export interface IGetResultsPayload {
    cameraId: string | null;
    dateStart: string;
    dateStop: string;
}

export async function getResults(
    dispatch: Dispatch,
    payload: IGetResultsPayload
) {
    try {
        dispatch({ type: ActionTypes.GET_RESULTS_START });

        const apiPayload: resultsService.IApiGetResultsPayload = {
            camera_id: payload.cameraId,
            date_start: payload.dateStart,
            date_stop: payload.dateStop,
        };

        // Get path list of algo results
        const response = await resultsService.getResults(apiPayload);

        if (response.status === 200) {
            const { data: pathList } = response;

            // Create algo results data
            const algosResults = await getAlgosResults(dispatch, pathList);

            dispatch({
                type: ActionTypes.GET_RESULTS_FINISH,
                payload: { data: algosResults },
            });
            dispatch({
                type: ActionTypes.SAVED_RESULTS_PATHS_LIST,
                payload: { pathList: pathList },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_RESULTS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_RESULTS_FAIL,
            payload: { error: err.message },
        });
    }
}
