import { createTheme } from "@material-ui/core";
import * as locales from "@material-ui/core/locale";

export const theme: any = {
    shape: {
        borderRadius: 5,
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: {
            light: "#ff5b45",
            main: "#e01a1a",
            dark: "#a50000",
            contrastText: "#fff",
        },
        secondary: {
            light: "#949494",
            main: '#666"',
            dark: '#3c3c3c"',
            contrastText: "#231f20",
        },
        text: {
            hint: "rgba(0, 0, 0, 0.38)",
            primary: "#000000",
            disabled: "rgba(0, 0, 0, 0.38)",
            secondary: "rgba(0, 0, 0, 0.54)",
        },
        action: {
            disabled: "rgba(128, 142, 149, 0.38)",
        },
        error: {
            dark: "#d32f2f",
            main: "#f44336",
            light: "#e57373",
            contrastText: "#fff",
        },
        info: {
            dark: "#1976d2",
            main: "#2196f3",
            light: "#64b5f6",
        },
        success: {
            dark: "#388e3c",
            main: "#4caf50",
            light: "#81c784",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        warning: {
            dark: "#f57c00",
            main: "#ff9800",
            light: "#ffb74d",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                textTransform: "capitalize",
            },
        },
    },
};

export default createTheme(theme, locales["frFR"]);
