import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTopOnMount() {
  // TODO: think about better solution without pathname dependancy
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
