import ResultsView from "features/results/view/results.view";
import SettingsView from "features/settings/view/settings.view";
import AlgosView from "features/algo/view/algos.view";
import CameraView from "features/camera/view/camera.view";

export const Endpoints = {
    RESULTS: "/",
    GENERAL: "/general",
    CAMERA: "/camera/:cameraId",
    ALGOS: "/algos/:cameraId",
};

export interface IRoute {
    path: string;
    component: React.ReactElement;
}

export const publicRoutes: IRoute[] = [
    {
        path: Endpoints.RESULTS,
        component: <ResultsView />,
    },
    {
        path: Endpoints.GENERAL,
        component: <SettingsView />,
    },
    {
        path: Endpoints.CAMERA,
        component: <CameraView />,
    },
    {
        path: Endpoints.ALGOS,
        component: <AlgosView />,
    },
];
