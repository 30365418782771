import { getAuthenticatedResourceInstance } from "services/api/helpers/axios.helpers";
const authenticatedResourceInstance = getAuthenticatedResourceInstance();

export async function getResource(endpoint: string): Promise<any> {
    return authenticatedResourceInstance.get(endpoint);
}

export async function getBinaryResource(endpoint: string): Promise<any> {
    return authenticatedResourceInstance.get(endpoint, {
        responseType: "arraybuffer",
        headers: {
            Accept: "image/png",
        },
    });
}
