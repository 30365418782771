import * as React from "react";

import * as resourceActions from "./resource.action";
import * as resourceSelector from "./resource.selector";

import { State, reducer, initialState } from "./resource.reducer";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

type ResourceProviderProps = { children: React.ReactNode };

// ---------------------------------------------------------------------
//                           CONTEXT && PROVIDER
//----------------------------------------------------------------------

const StateContext = React.createContext<
    { state: State; dispatch: resourceActions.Dispatch } | undefined
>(undefined);

function ResourceProvider({ children }: ResourceProviderProps) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const value = { state, dispatch };

    return (
        <StateContext.Provider value={value}>{children}</StateContext.Provider>
    );
}

// ---------------------------------------------------------------------
//                            CUSTOM HOOKS
//----------------------------------------------------------------------

function useResource() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error(`useResource must be used within a ResourceProvider`);
    }
    return [context.state, context.dispatch] as [
        State,
        resourceActions.Dispatch
    ];
}

export { ResourceProvider, useResource, resourceActions, resourceSelector };
