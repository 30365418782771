import { State } from "./algo.reducer";

export function selectAlgos(state: State) {
    return state.data;
}

export function selectAlgoByCameraId(state: State) {
    return function (cameraId: string) {
        const algos = selectAlgos(state);

        return algos.find((algo: any) => algo.camera_id === cameraId);
    };
}

export function selectAlgosJSONSchema(state: State) {
    return state.jsonSchema;
}
