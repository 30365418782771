const config = {
    drawerWidth: 240,
    canvas: {
        portrait: {
            width: 99,
            heigth: 400,
        },
        landscape: {
            width: 99,
            heigth: 400,
        },
    },
};

export default config;
