import { getAuthenticatedInstance } from "services/api/helpers/axios.helpers";
const authenticatedInstance = getAuthenticatedInstance();

export async function getCameras(): Promise<any> {
    return authenticatedInstance.get("/cameras");
}

export interface IApiPostCameraPayload {
    settings: any[];
}

export async function postCamera(payload: IApiPostCameraPayload): Promise<any> {
    return authenticatedInstance.post("/cameras", payload.settings);
}

export interface IApiAcquireCameraPayload {
    camera_id: string;
    mode: string;
}

export async function acquireCamera(
    payload: IApiAcquireCameraPayload
): Promise<any> {
    return authenticatedInstance.post("/camera_acquire", payload, {
        responseType: "arraybuffer",
        headers: {
            Accept: "image/png",
        },
    });
}

export interface IApiProcessCameraPayload {
    camera_id: string;
}

export async function processCamera(
    payload: IApiProcessCameraPayload
): Promise<any> {
    return authenticatedInstance.post("/camera_process", payload);
}
