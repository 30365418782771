import { ActionTypes } from "./actionTypes";
import * as cameraService from "services/api/camera.service";

import cameraConfig from "features/camera/camera.config";

// ---------------------------------------------------------------------
//                             TYPES
//----------------------------------------------------------------------

export type Dispatch = (action: Action) => void;

export type Action =
    | {
          type: ActionTypes.GET_CAMERAS_START;
      }
    | {
          type: ActionTypes.GET_CAMERAS_FINISH;
          payload: { data: any[] };
      }
    | {
          type: ActionTypes.GET_CAMERAS_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.POST_CAMERA_START;
      }
    | {
          type: ActionTypes.POST_CAMERA_FINISH;
      }
    | {
          type: ActionTypes.POST_CAMERA_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.ACQUIRE_CAMERA_START;
      }
    | {
          type: ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_COLOR;
          payload: { img: any };
      }
    | {
          type: ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_DEPTH;
          payload: { img: any };
      }
    | {
          type: ActionTypes.ACQUIRE_CAMERA_FAIL;
          payload: { error: string };
      }
    | {
          type: ActionTypes.PROCESS_CAMERA_START;
      }
    | {
          type: ActionTypes.PROCESS_CAMERA_FINISH;
      }
    | {
          type: ActionTypes.PROCESS_CAMERA_FAIL;
          payload: { error: string };
      };

// ---------------------------------------------------------------------
//                             ACTIONS
//----------------------------------------------------------------------

export enum Status {
    INIT = "Init",
    STARTING = "Starting",
    FINISH = "Finish",
    ERROR = "Error",
}

export async function getCameras(dispatch: Dispatch) {
    try {
        dispatch({ type: ActionTypes.GET_CAMERAS_START });

        const response = await cameraService.getCameras();

        if (response.status === 200) {
            const { data } = response;

            dispatch({
                type: ActionTypes.GET_CAMERAS_FINISH,
                payload: { data: data },
            });
        } else {
            dispatch({
                type: ActionTypes.GET_CAMERAS_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.GET_CAMERAS_FAIL,
            payload: { error: err.message },
        });
    }
}

export interface IPostCameraPayload {
    settings: any;
}

export async function postCamera(
    dispatch: Dispatch,
    payload: IPostCameraPayload
) {
    try {
        dispatch({ type: ActionTypes.POST_CAMERA_START });

        const apiPayload: cameraService.IApiPostCameraPayload = {
            settings: [payload.settings],
        };

        const response = await cameraService.postCamera(apiPayload);

        if (response.status === 200) {
            dispatch({
                type: ActionTypes.POST_CAMERA_FINISH,
            });
            getCameras(dispatch);
        } else {
            dispatch({
                type: ActionTypes.POST_CAMERA_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.POST_CAMERA_FAIL,
            payload: { error: err.message },
        });
    }
}

export interface IAcquireCameraPayload {
    cameraId: string;
    mode: string;
}

export async function acquireCamera(
    dispatch: Dispatch,
    payload: IAcquireCameraPayload
) {
    try {
        // Check if dispatch exist due to the async AcquisitionService
        if (!dispatch) return;

        dispatch({ type: ActionTypes.ACQUIRE_CAMERA_START });

        const apiPayload: cameraService.IApiAcquireCameraPayload = {
            camera_id: payload.cameraId,
            mode: payload.mode,
        };

        const response = await cameraService.acquireCamera(apiPayload);

        if (!dispatch) return;

        if (response.status === 200) {
            const { data } = response;

            if (payload.mode === cameraConfig.cameraMode.COLOR) {
                dispatch({
                    type: ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_COLOR,
                    payload: { img: data },
                });
            }
            if (payload.mode === cameraConfig.cameraMode.DEPTH) {
                dispatch({
                    type: ActionTypes.ACQUIRE_CAMERA_FINISH_MODE_DEPTH,
                    payload: { img: data },
                });
            }
        } else {
            dispatch({
                type: ActionTypes.ACQUIRE_CAMERA_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.ACQUIRE_CAMERA_FAIL,
            payload: { error: err.message },
        });
    }
}

export interface IProcessCameraPayload {
    cameraId: any;
}

export async function processCamera(
    dispatch: Dispatch,
    payload: IProcessCameraPayload
) {
    try {
        dispatch({ type: ActionTypes.PROCESS_CAMERA_START });

        const apiPayload: cameraService.IApiProcessCameraPayload = {
            camera_id: payload.cameraId,
        };

        const response = await cameraService.processCamera(apiPayload);

        if (response.status === 200) {
            dispatch({
                type: ActionTypes.PROCESS_CAMERA_FINISH,
            });
            getCameras(dispatch);
        } else {
            dispatch({
                type: ActionTypes.PROCESS_CAMERA_FAIL,
                payload: {
                    error: `Request failed with status code ${response.status}`,
                },
            });
        }
    } catch (err: any) {
        dispatch({
            type: ActionTypes.PROCESS_CAMERA_FAIL,
            payload: { error: err.message },
        });
    }
}
