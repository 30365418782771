export enum ActionTypes {
    GET_ALGOS_START = "Start getting algos",
    GET_ALGOS_FINISH = "Finish getting algos",
    GET_ALGOS_FAIL = "Fail to get algos",
    GET_ALGOS_JSON_SCHEMA_START = "Start getting algos json schema",
    GET_ALGOS_JSON_SCHEMA_FINISH = "Finish getting algos json schema",
    GET_ALGOS_JSON_SCHEMA_FAIL = "Fail to get algos json schema",
    POST_ALGO_START = "Start posting algo",
    POST_ALGO_FINISH = "Finish posting algo",
    POST_ALGO_FAIL = "Fail to post algo",
}
