export enum ActionTypes {
    GET_CAMERAS_START = "Start getting cameras",
    GET_CAMERAS_FINISH = "Finish getting cameras",
    GET_CAMERAS_FAIL = "Fail to get cameras",
    POST_CAMERA_START = "Start posting camera",
    POST_CAMERA_FINISH = "Finish posting camera",
    POST_CAMERA_FAIL = "Fail to post camera",
    ACQUIRE_CAMERA_START = "Start acquiring camera",
    ACQUIRE_CAMERA_FINISH_MODE_COLOR = "Finish acquiring camera mode color",
    ACQUIRE_CAMERA_FINISH_MODE_DEPTH = "Finish acquiring camera mode depth",
    ACQUIRE_CAMERA_FINISH = "Finish acquiring camera",
    ACQUIRE_CAMERA_FAIL = "Fail to acquiring camera",
    PROCESS_CAMERA_START = "Start processing camera",
    PROCESS_CAMERA_FINISH = "Finish processing camera",
    PROCESS_CAMERA_FAIL = "Fail to process camera",
}
